export const BLACK_LIST_EVENT_TYPES = [
  "TrackingPreparationPopupShown",
  "VenturePinataTooltipOpened",
  "PrivacyConsent",
  "AdFailure",
  "saAdvertisementWatched",
  "DDResultClient",
  "ExternalLinkReward",
  "NetworkTimeout",
  "LevelEnd",
  "AdvertisementNetworkRanOutOfAds",
  "DynamicDifficultyAccumulatedEvent",
  "WheelSpinCompletedForEnergyWheel",
  "BundleIntegrity",
  "BundleLoaded",
  "GoldenWheelRaceProgress",
  "GachaKeysViaVideoAdsTriggered",
  "LoadTime",
  "BattlePassError",
  "PurchaseCapReached",
  "SuspiciousActivity",
  "GameLoadingFunnel",
  "UserRatedApp",
  "WheelSpinButtonPressedForEnergyWheel",
  "SurveyLinkShown",
  "MapLoadTime",
  "TargetedAdsConsentStatusUpdated",
  "HackerDetected",
  "GoldenTicketAmountChanged",
  "WheelSpinButtonPressedForMultiWheelEventRewardWheel",
  "ContestMilestoneEventProgress",
  "CrossPromo",
  "CoinLevelAmountChanged",
  "FreeFiendUpgradesAmountChanged",
  "MilestoneEventProgress",
  "DDAccumulationClient",
  "GachaKeysViaVideoAdsDeclined",
  "MinutiasMostWantedProgress",
  "GachaKeysViaVideoAdsResult",
  "MultiWheelProgress",
  "YoutubeVideoWatched",
  "CoppaAgeConsent",
  "iOSATTPopupShown",
  "ServerReconnect",
  "CoinLevelEnd",
  "HackerRevoked ",
  "AdFailed",
  "SalePopupShown",
  "JumpStartEventProgress",
  "VideoPlayerResult",
  "AdWheelSpinCompleted",
  "RuntimeDataIntegrityCheck",
  "GemAmountChanged",
  "PurchaseBundleForGold",
  "SurveyCompleted",
  "AdFailedToLoad",
  "LostTreasureEventProgress",
  "BabySlugEventProgress",
  "BattlePassSceneOpened",
  "KeysGained",
  "PurchaseBundleForFree",
  "SeriouslyFriendsConfirmation",
  "PsfException",
  "HackedPlayer",
  "QuizTaken",
  "SeasonalMilestoneEventProgress",
  "BundleWallOffer",
  "GrindChallengeCompleted",
  "MinutianNewsCenter",
  "CoinLevelMissionEventProgress",
  "CoinLevelStart",
  "PremiumCoinLevelStart",
  "GrindChallengeRestarted",
  "LugsLootEventProgress",
  "CheckListEventProgress",
  "saAchievementUnlocked",
  "AdWheelSpinButtonPressed",
  "InboxOpened",
  "SocialTickerShown",
  "HackedBinary",
  "FixingFiends",
  "PsfGameSettingsDataGot",
  "PiggyBankFillStateUpdated",
  "AccountDeletionStarted",
  "GemConversion",
  "BundlesDownloaded",
  "BoardGameMegaDiceProgress",
  "WheelSpinButtonPressedForGoldenWheel",
  "HIAP",
  "FriendManagement",
  "PiggyBank",
  "AdvertisementRanOutOfAds",
  "WinStreakViaAdsPopUp",
  "LevelStart",
  "UIAP",
  "DynamicDifficultyResult",
  "AdWheelForcedSpinOpened",
  "BoardGameDiceEarned",
  "MessagingStatus",
  "TimeElapsedBeforePlayPressed",
  "WheelSpinCompletedForGoldenWheel",
  "WinStreakRecovered",
  "GrindChallengeStarted",
  "WheelSpinCompletedForMultiWheelEventRewardWheel",
  "balance_update",
  "SessionStarted"
];
